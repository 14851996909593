<div class="contact-us-container-2 mb-xl-0 mb-lg-0 mb-2">
  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-0">
    <div>
      <div class="text-center w-100 mb-3">
        <h1 class="title custom-section-h1">
          {{ "FEATURES.CONTACTS.TITLE" | translate }}
        </h1>
      </div>

      <div class="contact-us-left mx-2">
        <div class="contact-box-container bg-white">
          <mat-accordion class="w-100">
            <!-- address -->
            <mat-expansion-panel hideToggle [disabled]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div class="col-2">
                      <mat-icon>
                        {{ companyDetail.address.icon }}
                      </mat-icon>
                    </div>
                    <div
                      class="col-10 text-start d-flex justify-content-start align-items-start"
                    >
                      <app-contact-btn
                        [value]="companyDetail.address.fullAddress"
                        [href]="companyDetail.address.mapsUrl"
                        type="Address"
                      ></app-contact-btn>
                    </div>
                  </div>
                </mat-panel-title>
                <!-- <mat-panel-description> </mat-panel-description> -->
              </mat-expansion-panel-header>
            </mat-expansion-panel>

            <!-- email -->
            <mat-expansion-panel
              class="email-panel"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div class="col-2">
                      <mat-icon>
                        {{ companyDetail.contacts.email.icon }}
                      </mat-icon>
                    </div>
                    <div
                      class="col-10 text-start d-flex justify-content-start align-items-start"
                    >
                      <app-contact-btn
                        [value]="companyDetail.contacts.email.value"
                        type="Email"
                        [disableClick]="true"
                      ></app-contact-btn>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>
                <app-contact-form
                  *ngIf="panelOpenState"
                  [showTitle]="false"
                ></app-contact-form>
              </p>
            </mat-expansion-panel>

            <!-- phone -->
            <mat-expansion-panel
              hideToggle
              [disabled]="true"
              class="custom-disabled"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div
                    class="d-flex justify-content-start align-items-center w-100"
                  >
                    <div class="col-2">
                      <mat-icon>{{
                        companyDetail.contacts.phoneLink.icon
                      }}</mat-icon>
                    </div>
                    <div
                      class="col-10 text-start d-flex justify-content-start align-items-start"
                    >
                      <app-contact-btn
                        [value]="companyDetail.contacts.phoneLink.toString"
                        [href]="companyDetail.contacts.phoneLink.href"
                        type="Phone"
                      ></app-contact-btn>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <!-- social -->
            <hr class="w-100 mb-2" />
            <mat-expansion-panel
              hideToggle
              [disabled]="true"
              class="custom-disabled"
            >
              <mat-expansion-panel-header>
                <mat-panel-title
                  class="w-100 d-flex justify-content-around align-items-center"
                >
                  <!-- WhatsApp -->
                  <app-contact-item
                    [href]="companyDetail.contacts.whatsapp.href"
                    [iconSrc]="
                      iconsBasePath + companyDetail.contacts.whatsapp.icon
                    "
                  ></app-contact-item>

                  <!-- Instagram -->
                  <app-contact-item
                    [href]="companyDetail.contacts.instagram.href"
                    [iconSrc]="
                      iconsBasePath + companyDetail.contacts.instagram.icon
                    "
                  ></app-contact-item>

                  <!-- Facebook -->
                  <app-contact-item
                    [href]="companyDetail.contacts.facebook.href"
                    [iconSrc]="
                      iconsBasePath + companyDetail.contacts.facebook.icon
                    "
                  ></app-contact-item>

                  <!-- LinkedIn -->
                  <app-contact-item
                    [href]="companyDetail.contacts.linkedin.href"
                    [iconSrc]="
                      iconsBasePath + companyDetail.contacts.linkedin.icon
                    "
                  ></app-contact-item>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-0">
    <div class="img-container px-3">
      <img src="../../../assets/imgs/backgrounds/contact-us-bg.jpg" alt="" />
    </div>
  </div>
</div>
