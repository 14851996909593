import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private readonly publicKey = 'G6tsupOwXMiN8mTQP';
  private readonly serviceID = 'service_effvme2';

  sendEmail$(
    form: FormGroup<any>,
    send_to_mail: string
  ): Observable<EmailJSResponseStatus> {
    const { email, message } = form.value;
    const subject = '[STUDIOPIGGLE|WEB] Contact Form';
    const params = { send_to_mail, subject, email, message };
    return from(
      emailjs.send(this.serviceID, 'template_hzf3klc', params, this.publicKey)
    );
  }
}
