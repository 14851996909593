import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactModalFormComponent } from '../contact-modal-form/contact-modal-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-contact-btn',
  standalone: true,
  imports: [
    CommonModule,
    ContactModalFormComponent,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './contact-btn.component.html',
  styleUrl: './contact-btn.component.scss',
})
export class ContactBtnComponent {
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() type: 'Phone' | 'Email' | 'Address' | 'social' | '' = '';
  @Input() value: string = '';
  @Input() href: string = '';
  @Input() disableClick: boolean = false;

  constructor(private dialog: MatDialog) {}

  openMailDialog(mail: string): void {
    if (!this.disableClick) {
      this.dialog.open(ContactModalFormComponent, { data: mail });
    }
  }
}
