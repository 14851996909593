export class ContactInfo {
  email: ContactEmail;
  whatsapp: ContactWhatsApp;
  instagram: ContactInstagram;
  phoneLink: ContactPhone;
  facebook: ContactFacebook;
  linkedin: ContactLinkedin;

  constructor(phone: string, email: string) {
    this.email = new ContactEmail(email);
    this.whatsapp = new ContactWhatsApp(phone);
    this.instagram = new ContactInstagram('studiopiggle');
    this.facebook = new ContactFacebook('studiopiggle');
    this.linkedin = new ContactLinkedin('studio-piggle-a8263b305');
    this.phoneLink = new ContactPhone(phone);
  }
}

export class ContactWhatsApp {
  readonly api: string = 'https://api.whatsapp.com/send';
  readonly icon: string = 'whatsapp.webp';

  get href(): string {
    const phone = `phone=${this.prefix}${this.phone}`;
    const text = `text=${this.message}`;
    return `${this.api}?${phone}&${text}`;
  }

  constructor(
    public phone: string,
    public prefix: string = '+39',
    public message: string = 'Salve, vorrei informazioni per un appuntamento. Grazie'
  ) {}
}

export class ContactInstagram {
  icon: string = 'instagram.webp';

  get href(): string {
    return `https://www.instagram.com/${this.nickName}`;
  }

  constructor(public nickName: string) {}
}

export class ContactFacebook {
  icon: string = 'facebook.png';

  get href(): string {
    return `https://www.facebook.com/${this.nickName}`;
  }

  constructor(public nickName: string) {}
}

export class ContactLinkedin {
  icon: string = 'linkedin.png';

  get href(): string {
    return `https://it.linkedin.com/in/${this.nickName}`;
  }

  constructor(public nickName: string) {}
}
export class ContactEmail {
  label: string = 'Email:';
  icon: string = 'alternate_email';
  type: string = 'mailto:';
  get href(): string {
    return this.type + this.value;
  }

  constructor(public value: string) {}
}

export class ContactPhone {
  readonly icon: string = 'call';
  readonly label: string = 'Tel.:';
  readonly type: string = 'tel:';

  get href(): string {
    return this.type + this.prefix + this.phone;
  }
  get toString(): string {
    return `(${this.prefix}) ${this.phone}`;
  }

  constructor(public phone: string, public prefix: string = '+39') {}
}
