import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from '../../../features/contacts/services';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-contact-modal-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
  ],
  templateUrl: './contact-modal-form.component.html',
  styleUrl: './contact-modal-form.component.scss',
  providers: [
    { provide: ContactService, useClass: ContactService },
    { provide: DoctorsService, useClass: DoctorsService },
  ],
})
export class ContactModalFormComponent {
  showTitle: boolean = true;

  form: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control(''),
    message: this.formBuilder.control(''),
  });

  constructor(
    private dialogRef: MatDialogRef<ContactModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public send_to_mail: string,
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private _snackBar: MatSnackBar
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.send_to_mail) {
      this.contactService.sendEmail$(this.form, this.send_to_mail).subscribe(
        () => this.sendEmailCallback('Email Inviata!'),
        () => this.sendEmailCallback('Email Non Inviata!')
      );
    }
  }

  private sendEmailCallback(message: string) {
    this.dialogRef.close();
    this._snackBar.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
