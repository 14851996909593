<div class="mt-5 pt-5 common-page">
  <div class="row doctor w-100">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12">
      <div class="avatar-container">
        <div [id]="quarter" class="curved-corner">
          <img
            *ngIf="doctor && doctor.img"
            [src]="'../../../assets/' + doctor.img"
            class="avatar"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12">
      <div class="doctor-details h-100 px-3">
        <div class="mb-5">
          <div class="title" [innerHTML]="doctor?.name || '' | translate"></div>
          <div class="role" [innerHTML]="doctor?.role || '' | translate"></div>
        </div>
        <div class="mb-3 descriptions">
          <div
            class="description text-style mb-3"
            [innerHTML]="doctor?.description?.pt_1 || '' | translate"
          ></div>
          <div
            class="description text-style mb-3"
            [innerHTML]="doctor?.description?.pt_2 || '' | translate"
          ></div>
        </div>
        <!-- contact btn -->
        <div
          *ngIf="doctor && doctor.contacts"
          class="row justify-content-center align-content-center w-100"
        >
          <div class="col-xl-5 col-lg-5 col-md-12">
            <app-contact-btn-v2
              [icon]="doctor.contacts.phoneLink.icon"
              [label]="doctor.contacts.phoneLink.label"
              [value]="doctor.contacts.phoneLink.toString"
              [href]="doctor.contacts.phoneLink.href"
              hoverText="Chiama!"
              type="Phone"
            >
            </app-contact-btn-v2>
          </div>

          <div class="col-xl-5 col-lg-5 col-md-12">
            <app-contact-btn-v2
              [icon]="doctor.contacts.email.icon"
              [label]="doctor.contacts.email.label"
              [value]="doctor.contacts.email.value"
              type="Email"
              hoverText="Invia Email!"
            >
            </app-contact-btn-v2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
